html, body {
    margin: 0;
    font-family: 'Montserrat', helvetica, sans-serif;
    background: #222;
    color: #fff;
    font-size: 30pt;
    line-height: 1.5;
    padding: 2%;
}

h1 {
    margin: 0;
    padding: 0;
}

.teletype-prefix, .teletype-cursor {
    color: #00bd9c;
}

.button {
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #198754;

}

